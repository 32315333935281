import axios from 'axios';
// toast
import toast from "react-hot-toast";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

api.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('auth-token');
    
    if (typeof guestToken !== undefined) {
        // config.headers.Authorization = authToken;
        config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    return config;
});

api.interceptors.response.use(
    response => {
        if (response.status >= 400) {
            return Promise.reject(response);
        }
        return Promise.resolve(response)
    },
    error => {
        if (error.response.status === 403) {
            window.location.href = '/';
        }

        if (error.response.status === 401) {
            const { data } = error?.response
            
            if(data?.show_message) {
                toast.error(data.message);
            }
        }

        if (error.response.status === 400) {
            let { data } = error.response;

            if(typeof data === 'string') {
                toast.error(data);
            } else {
                Object.keys(data).map(key => {
                    data[key].map(message => toast.error(message))
                })
            }
        }

        if (error.response.status === 422) {
            const { errors } = error?.response?.data;

            Object.values(errors)?.map(error => (
                error.map(message => toast.error(message))
            ));
        }

        if (error.response.status === 409) {
            toast.error(error.response.data);
        }

        if (error.response.status === 500) {
            console.log(error.response.data);
            console.log('Error message: ' + error.response.data.message);
            //toast.error(error.response.data.message);
            toast.error('Falha na comunicação com o servidor!');
        }

        return Promise.reject(error);
    }
);

export default api;
