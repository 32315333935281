import toast from 'react-hot-toast';
import { useCallback, useEffect, useState } from 'react';
import Cropper from "react-easy-crop";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import getCroppedImg from 'helpers/cropper';
import StoreProductImageService from 'services/StoreProductImageService';

import imgHeaderHome from "../../../../../assets/img/home-header-1.png"; 
import noImage from "../../../../../assets/img/no_background.png"
import ImageService from '../../../../../services/ImageService';
import { Link } from 'react-router-dom';


function StoreBanner(props) {
    const [banner, setbanner] = useState("");
    const [department, setDepartment] = useState("");
    const [availableDepartments, setAvailableDepartments] = useState([]);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedBanner, setCroppedBanner] = useState({binary: null, preview: null});
    const [bannerPreview, setBannerPreview] = useState("");

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        ImageService.getAvailableCommerceDepartments().then((response) => (
            setAvailableDepartments(response)
        ));
    }, []);
    console.log(availableDepartments);
    const handleBanner = () => {
        if(croppedBanner?.binary === null) {
            toast.error('Selecione uma imagem');
            return;
        }

        let form = new FormData();
        form.append('banner', croppedBanner?.binary);
        form.append('department_id', department);

        toast.promise(ImageService.storeDepartmentBanner(form), {
            loading: 'Salvando...',
            success: 'Imagem salva com sucesso!',
            error: "Não foi possível salvar o banner"
        }).then(() => {
            setTimeout(() => {
                props.history.push('/admin/imagens/banner-departamento');
            }, 1500)
        });
    }

    const handleFileUpload = (event) => {
        setBannerPreview(URL.createObjectURL(event.target.files[0]));
    }

    const cropImage = useCallback(async () => {
        try {
           getCroppedImg(bannerPreview, croppedAreaPixels)
           .then(image => {
               setCroppedBanner({
                   preview: image?.previewImage,
                   binary: image?.binaryImage
               });

               toast.success("Imagem cortada com sucesso!");

           });
        } catch (error) {
            toast.error("Selecione uma imagem");
        }
    }, [croppedAreaPixels, bannerPreview]);

    return (
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Cadastrar banner</h3>
                    <Card>
                        <CardHeader>
                            <p className="category">*Cadastrar novo banner</p>
                        </CardHeader>
                        <CardBody>
                            <hr className='mtn-10' />
                            <Row className="mb-5">
                                <Col>
                                    <Link to="/admin/imagens/banner-departamento">
                                        <Button>
                                            Voltar para a listagem de banners
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className="mt-5 mb-3">
                                <Col md="4">
                                    <form>
                                        <label htmlFor="select-highlight-department">
                                            <h6>Departamento</h6>
                                        </label>
                                        <Input 
                                            type="select" 
                                            defaultValue={department}
                                            onChange={(event) => setDepartment(event.target.value)}
                                        >
                                        <option disabled={true} value="">Selecione um departamento disponível</option>
                                        {availableDepartments?.map(department => (
                                            <option 
                                                key={department?.CodigoInterno_DepartamentoCommerce}
                                                value={department?.CodigoInterno_DepartamentoCommerce}
                                            >
                                                {department?.Nome_DepartamentoCommerce}
                                            </option>
                                        ))}
                                        </Input>
                                    </form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="content-preview-img-form">
                                        {!croppedBanner?.src &&
                                            <div className="cropper-bg"></div>
                                        }
                                        <Cropper 
                                            crop={crop}
                                            showGrid={true}
                                            onCropChange={setCrop}
                                            image={bannerPreview}
                                            onCropComplete={onCropComplete}
                                            aspect={100 / 20}
                                        />
                                    </div>
                                </Col>
                                <Col md="6" className="d-flex align-items-center">
                                    <div className="content-preview-img-form">
                                        <img src={imgHeaderHome} style={{ width: "100%" }} alt="Imagem do 'header home'" />
                                        <div style={{ width: "100%", height: '96px', backgroundSize: "cover", backgroundImage: `url(${croppedBanner?.preview ? croppedBanner?.preview : noImage})` }} alt="Imagem cortada" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="d-flex justify-content-center">
                                    <h5>* É recomendado utilzar uma imagem Full HD (1920x1080)</h5>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col md="4">
                                    <FormGroup>
                                        <Input 
                                            type="file"
                                            className="form-control"
                                            name="image_preview_url"
                                            accept="image/*"
                                            onLoad={(event) => { event.target.value = null }} 
                                            onChange={handleFileUpload} 
                                            style={{ border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: '5px', fontSize: '13px', padding: '10px', marginTop: '4px', width: '100%', float: 'left', opacity: 1, position: 'initial' }} 
                                            required 
                                        />
                                    </FormGroup>
                                </Col> 
                                <Col md="2">
                                    <Button 
                                        className="btn btn-primary mt-2"
                                        onClick={cropImage}
                                        style={{ width: '140px', fontWeight: '600' }}
                                    >
                                        Cortar
                                    </Button>
                                </Col>
                            </Row> 
                            <hr />
                            <Row className="d-flex justify-content-center">
                                <Col md="2">
                                    <Button onClick={handleBanner} style={{ width: '100%' }}>Salvar</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default StoreBanner;