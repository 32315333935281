import Switch from "react-switch";
import toast from 'react-hot-toast';
import Cropper from "react-easy-crop";
import { Link } from "react-router-dom";
import { Slider } from '@material-ui/core';
import Loader from 'components/Loader/Loader';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';


import getCroppedImg from 'helpers/cropper';
import currency from 'helpers/currencyhelper';
import StoreProductImageService from 'services/StoreProductImageService';
import CeltaProductService from '../../../services/CeltaProductService';
import ModalWindow from "components/ModalWindow/ModalWindow";

const cropSizes = {
    "vertical": 1 / 2,
    "horizontal": 100 / 50,
    "default": 100 / 100
};

function Edit(props) {
    const productCode = props.match.params.code;

    const [loading, setLoading] = useState(true);

    const [product, setProduct] = useState({});
    const [productNewImage, setProductNewImage] = useState("");
        
    const [zoom, setZoom] = useState(1.2);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [cropSize, setCropSize] = useState(cropSizes?.default);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState({binary: null, preview: null});

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        getProductByCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProductByCode = () => {
        CeltaProductService.getProductByCode(productCode).then((response) => {
            let { data } = response;
            if (data.image) {
                const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
                const imageUrl = imageBaseUrl + data?.image?.path + data?.image?.name;
                data.imageSrc = imageUrl;
            }

            setProduct(data);
            setLoading(false);
        });
    }

    const handleUserFile = (event) => {
        setProductNewImage(URL.createObjectURL(event.target.files[0]));
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const cropImage = useCallback(async () => {
        try {
           getCroppedImg(productNewImage,croppedAreaPixels)
           .then(image => {
               setCroppedImage({
                   preview: image?.previewImage,
                   binary: image?.binaryImage
               });

               toast.success("Imagem cortada com sucesso!");
           });
        } catch (error) {
            console.log(error);
            toast.error("Selecione uma imagem");
        }
    }, [croppedAreaPixels, productNewImage]);
    
    const handleProduct = () => {
        if(croppedImage?.binary === null) {
            toast.error('Selecione uma imagem');
            return;
        }

        let form = new FormData();
        form.append('external_code', product?.CodigoInterno_Produto);
        form.append('image', croppedImage?.binary);

        toast.promise(StoreProductImageService.saveMainProductImage(form), {
            loading: 'Salvando...',
            success: 'Imagem salva com sucesso!'
        }).then((response) => {
            getProductByCode();
        });
    }

    const handleDeleteProductImage = () => {
        const externalCode = product?.CodigoInterno_Produto;

        toast.promise(StoreProductImageService.deleteProductImageByExternalCode(externalCode), {
            loading: 'Excluindo imagem.',
            success: 'Pronto!',
            error: 'Falha na comunicação com o servidor.'
        }).then(() => {
            setShowDeleteModal(false)
            getProductByCode();
        });
    }

    const handleCropSizeChange = inputEvent => {
        const selectedCropSize = inputEvent?.target?.value;

        setCropSize(cropSizes[selectedCropSize]);
    }

    const deleteImageModal = () => {
        if (!showDeleteModal) {
            return null;
        }

        return (
            <ModalWindow show={showDeleteModal} printable={false} width="300px">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col style={{ textAlign: 'end', marginTop: '-5px' }}>
                                <Button className="btn btn-danger btn-icon lanamaria-icons icon-simple-remove" title="Fechar" onClick={() => setShowDeleteModal(false)} />
                            </Col>
                            <Col md="12">
                                <h6 style={{ fontSize: '26px', textTransform: 'initial', marginTop: '4px' }}>Confirmar exclusão</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>Você confirma a exclusão da da foto do produto principal nº {product?.CodigoInterno_Produto}</p>
                        <small style={{ color: 'black' }}>
                            <b>*Obs.: ao confirmar, a imagem será deletada permanentemente</b>
                        </small>
                        <hr />
                        <Button className="btn btn-blue" type="submit" title="Excluir logo" style={{ width: '100%' }} onClick={() => handleDeleteProductImage(product?.CodigoInterno_Produto)}>
                            Sim
                        </Button>
                    </CardBody>
                </Card>
            </ModalWindow>
        );
    }

    return (
        <div className="content">
            {deleteImageModal()}
            {loading ?
                <Loader loading={loading} />
                :
                <Row>
                    <Col>
                        <h3 className="title">Editar produto</h3>
                        <Card>
                            <CardHeader>
                                <p className="category">*Editando o produto principal nº "{productCode}"</p>
                            </CardHeader>
                            <CardBody>
                                <hr className='mtn-10' />
                                <>
                                    <Row>
                                        <Col md="2">
                                            <Label>
                                                <h6>Código</h6>
                                            </Label>
                                            <Input type="number" value={product?.CodigoInterno_Produto} readOnly disabled />
                                        </Col>
                                        <Col md="7">
                                            <Label>
                                                <h6>Nome</h6>
                                            </Label>
                                            <Input type="text" value={product?.Nome_Produto} readOnly disabled />
                                        </Col>
                                        <Col md="3">
                                            <Label>
                                                <h6>Valor (e-commerce)</h6>
                                            </Label>
                                            <Input type="text" value={currency.handleToBRL(product?.PrecoVendaECommercePraticado_ProEmpEmbPre)} readOnly disabled />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="2">
                                            <h6>Departamentos</h6>
                                            <ul className="px-3">
                                                {product?.commerce_departments?.map(department => (
                                                    <li key={department?.CodigoInterno_DepartamentoCommerce}>
                                                        <h6  className="p-0 m-0">{department?.Nome_DepartamentoCommerce}</h6>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Col>
                                        {product?.commerce_sections?.length > 0 && (
                                            <Col md="2">
                                                <h6>Seção</h6>
                                                <ul className="px-3">
                                                    {product?.commerce_sections?.map(section => (
                                                        <li key={section?.CodigoInterno_SecaoCommerce}>
                                                            <h6  className="p-0 m-0">{section?.Nome_SecaoCommerce}</h6>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Col>
                                        )}

                                        {product?.commerce_group?.length > 0 && (
                                            <Col md="2">
                                                <h6>Grupo</h6>
                                                <ul className="px-3">
                                                    {product?.commerce_groups?.map(group => (
                                                        <li key={group?.CodigoInterno_GrupoCommerce}>
                                                            <h6  className="p-0 m-0">{group?.Nome_GrupoCommerce}</h6>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Col>
                                        )}
                                    </Row>
                                    {/*<Row>
                                        {product?.department &&
                                            <Col>
                                                <Label>
                                                    <h6>Departamento</h6>
                                                </Label>
                                                <Input type="text" value={`${product?.department?.CodigoInterno_Departamento}- ${product?.department?.Descricao_Departamento}`} readOnly disabled />
                                            </Col>
                                        }
                                        {product?.section &&
                                            <Col>
                                                <Label>
                                                    <h6>Sessão</h6>
                                                </Label>
                                                <Input type="text" value={`${product?.section?.CodigoInterno_Secao}- ${product?.section?.Descricao_Secao}`} readOnly disabled />
                                            </Col>
                                        }
                                        {product?.group &&
                                            <Col>
                                                <Label>
                                                    <h6>Grupo</h6>
                                                </Label>
                                                <Input type="text" value={`${product?.group?.CodigoInterno_Grupo}- ${product?.group?.Descricao_Grupo}`} readOnly disabled />
                                            </Col>
                                        }
                                    </Row>*/}
                                    <hr />
                                    <Col className="my-5">
                                        {
                                            product?.image 
                                                ? 
                                                    <div className="d-flex flex-column align-items-center">
                                                        <Row>
                                                            <Col>
                                                                <Label className="d-flex justify-content-center">
                                                                    <h6>Imagem salva</h6>
                                                                </Label>
                                                                
                                                                <div className="product-image-preview my-07">
                                                                    <img src={product?.imageSrc} alt="Imagem salva" style={{maxHeight: 300}}/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="my-5">
                                                                <Button 
                                                                    style={{width: 280}}
                                                                    className="btn-danger"
                                                                    onClick={() => setShowDeleteModal(true)}
                                                                >
                                                                    Excluir imagem do produto
                                                                </Button>
                                                            </Col>                                                     
                                                        </Row>
                                                    </div>
                                                : <h6 className="text-bold mx-4 d-flex justify-content-center"> * Ainda não foi cadastrado uma imagem para esse produto</h6>
                                                        
                                        }
                                   </Col>
                                   <hr />
                                    <Row className="d-flex justify-content-center">
                                        <Col md="6" style={{maxHeight: 300}}>
                                            <div className="content-preview-img-form" style={{height: 300}}>
                                                <div className="cropper-bg" style={{height: 300}}/>
                                               
                                                <Cropper 
                                                    crop={crop}
                                                    zoom={zoom}
                                                    showGrid={true}
                                                    onZoomChange={setZoom}
                                                    onCropChange={setCrop}
                                                    image={productNewImage}
                                                    onCropComplete={onCropComplete}
                                                    aspect={cropSize}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" className="d-flex justify-content-center my-5">
                                            {croppedImage?.preview &&
                                                <div className="product-image-preview d-flex flex-column align-items-center">
                                                    <Label>
                                                        <h6>Pré-visualização da imagem</h6>
                                                    </Label>
                                                    <img src={croppedImage?.preview} alt="Imagem cortada" style={{maxHeight: 300}}/>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md="4" className="mb-4">
                                            <Label>
                                                <h6>Zoom da imagem</h6>
                                            </Label>
                                            <Slider 
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                zoom={zoom}
                                                value={zoom}
                                                valueLabelDisplay="auto"
                                                className="custom-slider"
                                                aria-label="Zoom da imagem"
                                                onChange={(e, zoom) => setZoom(zoom)}
                                            />
                                        </Col>
                                        <Col md="8" className="d-flex flex-column align-items-center mt-1">
                                            <Col md="5">
                                                <Label for="">
                                                    <h6>Tamanho do corte</h6>
                                                </Label>
                                                <Input id="crop-size" type="select" defaultChecked="default" onChange={(event) => handleCropSizeChange(event)}>
                                                    <option value="default">Tamanho padrão</option>
                                                    <option value="vertical">Tamanho vertical</option>
                                                    <option value="horizontal">Tamanho horizontal</option>
                                                </Input>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col md="6">
                                            <FormGroup>
                                                <Col>
                                                    <Row>
                                                        <Input type="file" className="form-control" name="image_preview_url" accept="image/*" onLoad={(event) => { event.target.value = null }} onChange={handleUserFile} style={{ border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: '5px', fontSize: '13px', padding: '10px', marginTop: '4px', width: '100%', float: 'left', opacity: 1, position: 'initial' }} required />
                                                    </Row>
                                                    <Row>
                                                        <Button className="btn btn-primary mt-2" onClick={cropImage} style={{ width: '140px', fontWeight: '600' }}>Cortar</Button>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                        </Col> 
                                    </Row> 
                                    <Row>
                                        <Col>
                                            <Link to="/admin/produtos/principais">
                                                <Button className="btn-danger mr-5">Voltar aos produtos principais</Button>
                                            </Link>
                                        </Col>

                                        
                                        <Link to={`/admin/produtos/principais/${product?.CodigoInterno_Produto}/variantes`}>
                                            <Button className="btn-success">
                                                Ver todas as variantes dessse produto
                                            </Button>
                                        </Link>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <Button onClick={handleProduct} style={{ width: '140px' }}>Salvar</Button>
                                        </Col>
                                    </Row>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}


export default Edit;