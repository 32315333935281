import Switch from "react-switch";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FaPlusCircle, FaMinusCircle, FaSearch } from "react-icons/fa";
import { Col, Row, Card, CardHeader, CardBody, Collapse, Input, Button} from "reactstrap";


import CeltaProductService from "services/CeltaProductService";
import HomePageService from "services/HomePageService";
import toast from "react-hot-toast";
import ListCustomSections from "./ListCustomSections";

const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
const notFoundImage = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg";

function EditHomePage () {
    const [highlightProducts, setHighlightProducts] = useState([]);
    const [highlightDepartments, setHighlightDepartments] = useState([]);
    const [highlightProductsSearch, setHighlightProductsSearch] = useState({});
    const [insertedHighlightProducts, setInsertedHighlightProducts] = useState([]);
    const [activeHighlightDepartment, setActiveHighlightDepartment] = useState(null);
    const [showCustomSectionCollapse, setShowCustomSectionCollapse] = useState(false);
    const [showHighlightProductsCollapse, setShowHighlightProductsCollapse] = useState(false);
    const [addMoreProducts, setAddMoreProducts] = useState(false);
    const [aditionalProducts, setAditionalProducts] = useState([...Array(3)]);
    const [activeProducts, setActiveProducts] = useState([]);

    useEffect(() => {
        getSelectedHighlightDepartments();
    }, []);

    useEffect(() => {
        getActiveHighlightProducts();
    }, [activeHighlightDepartment]);

    const getSelectedHighlightDepartments = () => {
        HomePageService.getHighlightedDepartments().then(response => {
            const highlightDepartments = response.data;
            setHighlightDepartments(highlightDepartments);
        });
    }
    
    const getActiveHighlightProducts = () => {
        if(activeHighlightDepartment === null) return;
        
        HomePageService.getHighlightedProductsById(activeHighlightDepartment).then(response => {
            const products = response.data;
            const activeProducts = products?.map(product => {
                return {
                    highlightProductId: product.id,
                    isActive: product.is_active
                }
            });

            setHighlightProducts(products);
            setActiveProducts(activeProducts);
        });
    }


    const handleSearchProduct = (event, highlightId, isAditionalProduct = false) => {
        const search = event.target.value;

        setHighlightProductsSearch(prevState => {
            return {
                ...prevState,
                [highlightId]: search,
                isAditionalProduct
            }
        })
    }

    const searchProducts = (event, highlightProductId, externalSection, highlightDepartmentId) => {
        event.preventDefault();

        const userSearch = highlightProductsSearch[highlightProductId];
        
        toast.promise(CeltaProductService.searchProductByNameOrCode(userSearch), {
            loading: 'Carregando...',
            error: 'O produto não foi ser encontrado',
            success: 'Pronto!'
        }).then(response => {
            const searchedProduct = response.data;
            const searchedProductSection = searchedProduct.section.CodigoInterno_Secao;

            if(externalSection !== searchedProductSection) {
                toast.error('O departamento do produto pesquisado é diferente do departamento de destaque');
                return;
            }
            
            if(hasDuplicatedValues(searchedProduct)) {
                toast.error('O produto já foi inserido');
                return;
            }

            const shouldUpdateProducts = insertedHighlightProducts.find(product => product.insertedProducts.highlightProductId === highlightProductId);

            if(shouldUpdateProducts) {
                updateInsertedHighlightProductsArray(highlightProductId, searchedProduct);
            } else {
                addProductInsertHighlightProductsArray(highlightProductId, searchedProduct);
            }

            updateHighlightProductsList(highlightProductId, highlightDepartmentId, searchedProduct)
        });
    }


    const searchAditionalProduct = (event, id) => {
        event.preventDefault();
        const userSearch = highlightProductsSearch[id];

        toast.promise(CeltaProductService.searchProductByNameOrCode(userSearch), {
            loading: 'Carregando...',
            error: 'O produto não foi ser encontrado',
            success: 'Pronto!'
        }).then(response => {
            const searchedProduct = response.data;

            const products = aditionalProducts.map(product => product?.external_code);
            const productCode = searchProducts.CodigoInterno_Produto;
            const productAlreadyInserted = products.indexOf(productCode);
            

            const searchedProductSection = searchedProduct.section.CodigoInterno_Secao;
            const highlightDepartment = highlightProducts[0]?.external_section

            if(searchedProductSection !== highlightDepartment) {
                toast.error('O departamento do produto pesquisado é diferente do departamento de destaque');
                return;
            }

            if(productAlreadyInserted) {
                toast.error('O produto já foi inserido');
                return;
            }

            setAditionalProducts(prevState => {
                return prevState.map((product, index) => {
                    if(index === id) {
                        return {
                            aditionalProducts: {
                                id,
                                product: searchedProduct
                            }
                        };
                    }
    
                    return product;
                });
            });
        });
    }

    const hasDuplicatedValues = newProduct => {
        const products = highlightProducts.map(product => product.external_code);
        const productCode = newProduct.CodigoInterno_Produto;
        const productAlreadyInserted = products.indexOf(productCode);
        
        return (productAlreadyInserted >= 0) ? true : false;
    }

    const addProductInsertHighlightProductsArray = (highlightProductId, newProduct) => {
        setInsertedHighlightProducts(prevState => {
            return [
                ...prevState,
                {
                    insertedProducts: {
                        highlightProductId,
                        searchedProduct: newProduct
                    }
                }
            ];
        });
    }

    const updateInsertedHighlightProductsArray = (highlightProductId, newProduct) => {
        setInsertedHighlightProducts(prevState => {
            return prevState.map(product => {
                if(product.insertedProducts.highlightProductId === highlightProductId) {
                    return {
                        insertedProducts: {
                            highlightProductId,
                            searchedProduct: newProduct
                        }
                    };
                }

                return product;
            });
        });
    }
    
    const updateHighlightProductsList = (highlightProductId, highlightDepartmentId, newProduct) => {
        setHighlightProducts(prevState => {
            return prevState.map(product => {
                if(product.id === highlightProductId) {
                    return {
                        id: highlightProductId,
                        external_code: newProduct.CodigoInterno_Produto,
                        external_section: newProduct.CodigoInterno_Secao,
                        highlight_department_id: highlightDepartmentId,
                        product: newProduct
    
                    };
                }

                return product;
            });
        });
    }

    const saveHighlightProducts = () => {
        const totalActiveProducts = activeProducts.filter(value => (value.isActive === true )).length

        if([3, 6].includes(totalActiveProducts)) {
            toast.promise(HomePageService.saveHighlightProducts(activeHighlightDepartment, insertedHighlightProducts, aditionalProducts, activeProducts), {
                loading: 'Salvando produto...',
                success: 'Pronto!',
                error: 'O produto não pode ser adicionado'
            });
        } else {
            toast.error('Devem estar ativos 3 ou 6 produtos ativos');
        }
    }
    const handleIsProductActive = (productId, active) => {
        setHighlightProducts(prevState => {
            return prevState.map(product => {
                if(product.id === productId) {
                    return {
                       ...product,
                       is_active: active
                    }
                }

                return product;
            });
        });

        setActiveProducts(prevState => {
            return prevState.map(product=> {
                if(product.highlightProductId === productId) {
                    return {
                       ...product,
                       isActive: active
                    }
                }

                return product;
            });
         })
    };
    
    const handleIsDepartmentActive = (highlightDepartmentId, active) => {
        setHighlightDepartments(prevState => {
            return prevState.map(department => {
                if(department.id === highlightDepartmentId) {
                    return {
                       ...department,
                       
                       is_active: active
                    }
                }

                return department;
            });
        });

        toast.promise(HomePageService.updateDepartmentVisibility(active, highlightDepartmentId), {
            loading: 'Carregando...',
            success: 'Visibilidade atualizada.',
            error: 'Não foi possível atualizar o departamento'
        });
    }

    const showSaveButton = () => {
        const totalActiveProducts = activeProducts.filter(value => (value.isActive === true )).length;

        if (insertedHighlightProducts.length > 0  
            || aditionalProducts.filter(value => (value !== undefined )).length === 3 
            || [3, 6].includes(totalActiveProducts)) {
            return true;
        }

        return false;
    }

    return (
        <div className="content">
            <Row>
                <Col>
                    <h3>Página principal</h3>
                </Col>
            </Row>
            <Card>
                <CardHeader>
                    <h6>Edição das seções página principal da loja</h6>
                    <hr />
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <button
                              className="btn-dropdown"
                              onClick={() => setShowHighlightProductsCollapse(prevState => !prevState)}
                            >
                                <h6 className="m-0 text-justify">
                                    <b>Editar a seção de produtos em destaque</b>
                                </h6>
                                <span>
                                    {showHighlightProductsCollapse ? <FaMinusCircle /> : <FaPlusCircle />}
                                </span>
                            </button>
                        </Col>
                    </Row>
                    <Collapse isOpen={showHighlightProductsCollapse}>
                        <Tabs 
                            justify 
                            className="mt-4"
                            onSelect={key => setActiveHighlightDepartment(key)}
                         >
                            {highlightDepartments?.map(highlightDepartment => (
                                <Tab 
                                    key={highlightDepartment?.id}
                                    eventKey={highlightDepartment?.id}
                                    title={highlightDepartment.section?.Descricao_Secao}
                                >
                                    <Row className="mt-3 mb-5 d-flex align-items-center">
                                        <Col md="8" className="mt-4">
                                            <Link to="pagina-principal/departamento-destaque">
                                                <Button>Criar um novo departamento de destaque</Button>
                                            </Link>
                                        </Col>
                                        {highlightProducts.length > 0 &&
                                            <Col md="4" className="d-flex align-items-center">
                                                <label htmlFor="is-department-active" className="mt-3">
                                                    <h6>Inativo / Ativo</h6>
                                                </label>
                                                <Switch
                                                    width={45}
                                                    height={20}
                                                    className="ml-2"
                                                    onColor="#8c5899"
                                                    id="is-department-active"
                                                    checked={highlightDepartment.is_active}
                                                    onChange={value => handleIsDepartmentActive(highlightDepartment?.id, value)}                                                
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    {highlightProducts?.length === 3 && 
                                        <Row className="mt-5">
                                            <Col>
                                                <h6>Quantidade de produtos a serem exibidos</h6>
                                                <Button onClick={() => setAddMoreProducts(prevState => !prevState)}>Adicionar mais produtos</Button>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="d-flex justify-content-center">
                                        <Col md="6">
                                            <h5 className="m-0">* Devem estar ativos 6 ou 3 produtos por departamento de destaque</h5>
                                        </Col>
                                    </Row>
                                    {highlightProducts?.length > 0 && 
                                        <Row>
                                            {highlightProducts?.map(product => (
                                                <Col key={product?.external_code}  md="4">
                                                    <form onSubmit={event => searchProducts(event, product.id, product.external_section, product.highlight_department_id, false)}>
                                                        <label className="mt-5" htmlFor="highlight-first-product">
                                                           <h6>Pesquisar produto</h6>
                                                        </label>
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                className="custom-search-input"
                                                                placeholder="Pesquise por nome do produto ou código"
                                                                onChange={event => handleSearchProduct(event, product.id)}
                                                            />
                                                            <button 
                                                                type="submit"
                                                                className="custom-search-button"
                                                            >
                                                                <FaSearch />
                                                            </button>
                                                        </div>
                                                    </form>
                                                    <Row className="border rounded m-1 my-5">
                                                        <Col md="12" className="d-flex justify-content-center mt-4">
                                                            <h6>Produto</h6>
                                                        </Col>
                                                        <Row className="mt-4">
                                                            <Col md="12" className="mx-2">
                                                                <h6>{product?.product?.Nome_Produto}</h6>
                                                            </Col>
                                                            <Col className="d-flex justify-content-center">
                                                                <img
                                                                    height={150}
                                                                    width={150}
                                                                    className="border m-3"
                                                                    src={product?.product?.image ? imageUrl + product?.product?.image?.path + product?.product?.image?.name : notFoundImage }
                                                                 />
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12" className="d-flex align-items-center justify-content-center">
                                                            <label htmlFor="is-department-active" className="mt-3">
                                                                <h6>Inativo / Ativo</h6>
                                                            </label>
                                                            <Switch
                                                                width={45}
                                                                height={20}
                                                                className="ml-2"
                                                                onColor="#8c5899"
                                                                id="is-department-active"
                                                                checked={product?.is_active}
                                                                onChange={value => handleIsProductActive(product?.id, value)}                                                
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                            {addMoreProducts && 
                                                <>
                                                    {aditionalProducts.map((product, index) => (
                                                        <Col md="4" className="mt-5" key={index}>
                                                            <form  onSubmit={event => searchAditionalProduct(event, index)}>
                                                                <label className="mt-5" htmlFor="highlight-first-product">
                                                                   <h6>Pesquisar produto</h6>
                                                                </label>
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="text"
                                                                        className="custom-search-input"
                                                                        placeholder="Pesquise por nome do produto ou código"
                                                                        onChange={event => handleSearchProduct(event, index, true)}
                                                                    />
                                                                    <button 
                                                                        type="submit"
                                                                        className="custom-search-button"
                                                                    >
                                                                        <FaSearch />
                                                                    </button>
                                                                </div>
                                                            </form>
                                                            <Row className="border rounded m-3 my-5">
                                                                <Col md="12" className="d-flex justify-content-center mt-4">
                                                                    <h6>Novo Produto</h6>
                                                                </Col>
                                                                <Row className="mt-4">
                                                                    <Col md="12" className="mx-2 d-flex justify-content-center">
                                                                        <h6>
                                                                            {product?.aditionalProducts?.product?.Nome_Produto}
                                                                        </h6>
                                                                    </Col>
                                                                    <Col md="11" className="d-flex justify-content-center ml-2">
                                                                        <img
                                                                            height={150}
                                                                            width={150}
                                                                            className="border mx-5 my-4"
                                                                            src={product?.aditionalProducts?.product?.image ? imageUrl + product?.aditionalProducts?.product?.image?.path + product?.aditionalProducts?.product?.image?.name : notFoundImage}
                                                                         />
                                                                    </Col>
                                                                </Row>
                                                            </Row>
                                                        </Col>
                                                    ))}
                                                </>
                                            }
                                        </Row>
                                    }
                                </Tab>
                            ))}
                        </Tabs>
                        {showSaveButton() &&
                            <Row className="mt-5">
                                <Col className="d-flex justify-content-center">
                                   <Button onClick={() => saveHighlightProducts()}> Salvar </Button>
                                </Col>
                            </Row>
                        }
                    </Collapse>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <button
                              className="btn-dropdown"
                              onClick={() => setShowCustomSectionCollapse(prevState => !prevState)}
                            >
                                <h6 className="m-0 text-justify">
                                    <b>Editar a seção customizada</b>
                                </h6>
                                <span>
                                    {showCustomSectionCollapse ? <FaMinusCircle /> : <FaPlusCircle />}
                                </span>
                            </button>
                        </Col>
                    </Row>
                    <Collapse isOpen={showCustomSectionCollapse}>
                        <ListCustomSections />
                    </Collapse>
                </CardBody>
            </Card>
        </div>
    );
}

export default EditHomePage;