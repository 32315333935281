import api from "./api";

class UserService {
    async getUsers(page, perPage) {
        const response = api.get(`/admin/users/list?page=${page}&per_page=${perPage}`);
        return response;
    }

    async getUserById(userId) {
        const response = api.get(`/admin/users/show?id=${userId}`);
        return response;
    }

    async editUser(userId, userData, storedUserEmail) {
        const requestBody = {
            ...userData,
            email: userData.email === storedUserEmail ? null : userData.email,
            id: userId
        }

        const response = api.patch("/admin/users/edit", requestBody);
        return response;
    }

    async deleteUserByUserId(id) {
        const response = api.delete(`/admin/users/delete?id=${id}`);
        return response;
    }

    async createUser(requestBody) {
        const response = api.post("/admin/users/create", requestBody); 
        return response; 
    }

    async session() {
        const response = api.get("/admin/auth/session", {teste: 'caio'});
        return response;
    }

    async sendRecoverPasswordEmail(email) {
        const response = api.get(`/admin/users/recover-password?email=${email}`);
        return response;
    }
}

export default new UserService();