import { useState } from "react";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { ChromePicker } from "react-color";
import { Button, Col, Form, Input, Row } from "reactstrap";

import CeltaProductService from "services/CeltaProductService";
import HomePageService from "services/HomePageService";


const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
const notFoundImage = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg";

function SingleBannerSection() {
	const [bannerUrl, setBannerUrl] = useState("");
	const [bannerBlob, setBannerBlob] = useState({});
	const [productsList, setProductsList] = useState([]);
	const [productsSearch, setProductsSearch] = useState([]);
	const [fontColor, setFontColor] = useState("#fff");
	const [backgroundColor, setBackgroundColor] = useState("#8c5899");

	const handleBannerUpload = event => {
		const file = event.target.files[0];
		const fileReader = new FileReader();

		fileReader.readAsArrayBuffer(file);
		fileReader.onload = function() {
			const blob = new Blob([fileReader.result]);
			const imageUrl = URL.createObjectURL(blob);

			setBannerBlob(blob);
			setBannerUrl(imageUrl);
		}
    }

    const handleProductSearch = (event, listId) => {
        const userSearch = event.target.value;

        setProductsSearch(prevState => {
            return {
                ...prevState,
                [listId]: userSearch
            }
        })
    }

    const handleSearch = (event, listId) => {
        event.preventDefault();

        const userSearch = productsSearch[listId];
        
        toast.promise(CeltaProductService.searchProductByNameOrCode(userSearch), {
            loading: 'Pesquisando produto',
            success: 'Pronto!',
            error: 'O produto não foi encontrado'
        }).then(response => {
            const product = response.data;

            const shouldUpdateList = productsList?.find(product => product?.listId === listId);
			const duplicatedProductsExists = productsList?.some(prod => prod?.product?.CodigoInterno_Produto === product?.CodigoInterno_Produto);
			
			if(duplicatedProductsExists) {
				toast.error('Produto já inserido.');
				return;
			}

            if(shouldUpdateList) {
                updateProductList(listId, product);
            } else {
                addProductInList(listId, product);
            }
        });
    }

	const addProductInList = (listId, product) => {
        setProductsList(prevState => {
            return [
                ...prevState,
                {
                    listId,
                    product 
                }
            ] 
        });
    }

	const updateProductList = (listId, newProduct) => {
        setProductsList(prevState => {
            return prevState.map(product => {
                if(product?.listId === listId) {
                    return {
                        listId,
                        product: newProduct
                    }
                }

                return product;
            });
        });
    }

	const getProductName = (productId) => {
        const productData  = productsList?.find(product => product?.listId === productId);   

        return productData?.product?.Nome_Produto;
    }

    const getProductImage = (productId) => {
        const productData = productsList?.find(product => product?.listId === productId); 

        if(productData && productData?.product?.image) {
            const imagePath = productData?.product?.image?.path;
            const imageName = productData?.product?.image?.name;
 
            return imageBaseUrl + imagePath + imageName;
        } else {
            return notFoundImage;
        }
    }

	const createCustomSection = () => {
		let form = new FormData();
		
		form.append('main_banner', bannerBlob);
		form.append('section_type', 1);
		form.append('is_active', false);
		form.append('font_color', fontColor);
		form.append('background_color', backgroundColor);
		form.append('products', JSON.stringify(productsList));

		toast.promise(HomePageService.createCustomSection(form), {
			loading: 'Criando seção customizada',
			success: 'Pronto!',
			error: 'Falha na criação da seção customizada.'
		});
	}

	return(
		<>
			<Row>
				<Col md="12" className="d-flex justify-content-center">
					<Col md="10" className="p-0" style={{backgroundColor: backgroundColor, maxWidth: "820px"}}>
						{bannerUrl 
							? <img width="100%" height="400px" src={bannerUrl} /> 
							: 
								<div className="custom-section-banner">
									<p>Banner principal</p>
								</div>
						}
					</Col>
				</Col>
			</Row>
			<Row>
				<Col md="12" className="d-flex mb-5 justify-content-center">
					<Col md="12" className="p-0 d-flex" style={{backgroundColor: backgroundColor, maxWidth: "820px"}}>
						{productsList.length > 0
							?
								<>
									<Row className="d-flex justify-content-center">
										{productsList?.map(product => (
												<Col key={product?.listId} md="5">
													<Col>
														<img 
															width="90"
															height="90"
															src={imageBaseUrl + product?.product?.image?.path + product?.product?.image?.name}
														/>
													</Col>
													<Col>
														<h6 style={{color: fontColor}}> {product?.product?.Nome_Produto} </h6>
													</Col>
												</Col>
										))}
									</Row>
								</>
							:                                        
								<div className="custom-section-banner">
								<p>Escolha 6 produtos </p>
								</div>
						}
					</Col>
				</Col>
			</Row>

			<Row className="mt-5">
			<Col md="3">
					<label htmlFor="background-color">
						<h6>Cor de fundo: </h6>
					</label>

					<ChromePicker
						isOpen={true}
						color={backgroundColor || '#fff'}
						onChangeComplete={color => setBackgroundColor(color.hex)}
					/>
				</Col>
				<Col md="3">
					<label htmlFor="background-color">
						<h6>Cor da fonte: </h6>
					</label>

					<ChromePicker
						isOpen={true}
						color={fontColor || '#fff'}
						onChangeComplete={color => setFontColor(color.hex)}
					/>
				</Col>
			</Row> 
		    <Row className="mt-5">
				<Col md="12" className="d-flex justify-content-center mt-5">
                   <h5>* É recomendado o uso de uma imagem Full HD (1920x1080)</h5>
                </Col>
				<Col md="6">
					<Col md="12">
						<label htmlFor="main-banner">
							<h6>Banner principal:</h6>
						</label>    
					</Col>
					<Col>
						<Input 
							required 
							type="file"
							accept="image/*"
							id="main-banner"
							name="image_preview_url"
							className="form-control"
							onChange={event => handleBannerUpload(event)}
						/>
					</Col>
				</Col>
			</Row>

			<Row className="mt-4">
				{[...Array(6)].map((value, index) => (
					<Col key={index} md="4" className="mt-5">
						<form onSubmit={event => handleSearch(event, index)}>
							<label className="mt-5" htmlFor="highlight-first-product">
							<h6>Alterar produto</h6>
							</label>
							<div className="d-flex align-items-center">
								<input
									type="text"
									className="custom-search-input"
									placeholder="Pesquise por nome do produto ou código"
									onChange={event => handleProductSearch(event, index)}
								/>
								<button 
									type="submit"
									className="custom-search-button"
								>
									<FaSearch />
								</button>
							</div>
						</form>
						<Row className="border rounded m-1 my-5 d-flex flex-column justify-content-center align-items-center">
							<Col md="12" className="d-flex mt-3 justify-content-center">
								<h6>Produto</h6>
							</Col>
							<Col md="12" className="mx-2 mt-3 d-flex justify-content-center" >
								<h6> { getProductName(index) } </h6>
							</Col>
							<Col md="12" className="d-flex justify-content-center">
								<img
									height={150}
									width={150}
									className="border m-3"
									src={getProductImage(index)}
								/>
							</Col>
						</Row>
					</Col>
				))}
			</Row>
			<hr />
		    <Row className="d-flex justify-content-center">
				<Col md="2">
					<Button onClick={createCustomSection}>
						Salvar
					</Button>
				</Col>
			</Row>
		</>
	);
}

export default SingleBannerSection;