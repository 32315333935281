import React, { useEffect, useState } from 'react';
// Services
import AuthService from 'services/AuthService';
//Styles
import toast, { Toaster } from "react-hot-toast";
import background from 'assets/img/background-ss.svg';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useContext } from 'react';
import AuthContext from 'contexts/Auth/AuthContext';
import UserService from 'services/UserService';

function Login(props) {
    const authContext = useContext(AuthContext)
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [recoverPasswordEmail, setRecoverPasswordEmail] = useState("");
    const [showForgetPasswordInput, setShowForgetPasswordInput] = useState(false);

    useEffect(() => {
        if (props.customer) {
            setEmail(props.customer.email);
            setPassword(props.customer.password);
        }
    }, [props.customer]);

    const handleEmail = (e) => {
        let typed = e.target.value;
        setEmail(typed);
    };

    const handlePassword = (e) => {
        let typed = e.target.value;
        setPassword(typed);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === '' || password === '') {
            return toast.error('Preencha todos os campos para continuar!');
        }

        // email validation
        if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            return toast.error('Insira um e-mail válido!');
        }

        toast.promise(AuthService.auth(email, password), {
            success: "Sucesso",
            error: "Não foi possível fazer o login",
            loading: "fazendo login",
        }).then(response => {
            const { data } = response;
            authContext.authenticateUser(data);

            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        });
    }

    const handleSendRecoverEmail = () => {
        toast.promise(UserService.sendRecoverPasswordEmail(recoverPasswordEmail), {
            loading: "Enviando email...",
            error: "Não foi possível enviar o email.",
            success: "Email enviado"
        })
    }

    return (
        <div className="content">
            <div className="container">
                <Card>
                    <CardBody>
                        <Row>
                            <Toaster
                                position="top-right"
                                reverseOrder={false}
                            />
                            <Col md="6 mt-4">
                                <img src={background} alt="" className="img-fluid" />
                            </Col>
                            <Col md="6">
                                <div className="row justify-content-center">
                                    <Col md="8">
                                        <div className="mb-4">
                                            <h3>Acessar</h3>
                                            <p className="mb-4">Digite abaixo as suas credenciais para acessar sua conta.</p>
                                        </div>
                                        <Form onSubmit={handleSubmit}>
                                            <FormGroup>
                                                <Label>
                                                    <h6>E-mail</h6>
                                                </Label>
                                                <Input type="email" placeholder="Insira o e-mail" className="form-control" value={email || ''} onChange={handleEmail} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>
                                                    <h6>Senha</h6>
                                                </Label>
                                                <Input type="password" placeholder="Insira a senha" className="form-control" value={password || ''} onChange={handlePassword} />
                                            </FormGroup>
                                            <div className="d-flex mb-3 align-items-center">
                                                {/*
                                                <label className="control control--checkbox mb-0">
                                                    <span className="caption">Lembrar</span>
                                                    <input type="checkbox" checked="" />
                                                    <div className="control__indicator"></div>
                                                </label>
                                                */}
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                        <a 
                                                            href="#"
                                                            className="forgot-pass"
                                                            onClick={() => setShowForgetPasswordInput(prevState => !prevState)}
                                                        >
                                                            Esqueci minha senha
                                                        </a>
                                                    </Col>
                                                    {showForgetPasswordInput &&
                                                        <>
                                                            <Col md="12" className="mb-2">
                                                                <Input
                                                                    onChange={event => setRecoverPasswordEmail(event.target.value)} 
                                                                    placeholder="Insira aqui o e-mail de recuperação de senha" 
                                                                />
                                                                </Col>
                                                            <Col md="3">
                                                                <Button onClick={handleSendRecoverEmail}>Enviar</Button>
                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                            </div>
                                            <Button className="btn  btn-lg btn-block">ACESSAR</Button>
                                        </Form>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default Login;